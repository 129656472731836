import React from 'react'
import { navigate } from 'gatsby-link';
import ContentPageLayout from '../../../components/ContentPageLayout'
import SCButton from '../../../components/buttons/SCButton';

const index = () => {

    const serverUri = 'https://cms.shiftmexico.com/uploads/banner_superior_8acc5551bb.png';

    return (
        <ContentPageLayout>
            <div className="flex relative" style={{ height: 240, width: "100%" }}>
                    <img
                        style={{ width: "100%", height: "240px", objectFit: "cover" }}
                        src={serverUri}
                        alt={'Contenido no disponible'}
                    />
                <div className="absolute flex flex-col p-5 justify-end items-start h-full">
                    <h1 className="text-white ">Página no disponible</h1>
                    <p className="text-white">Disculpa las molestias</p>
                </div>
            </div>
            <p className='text-center px-4 md:px-0 my-12 text-xl md:my-16 md:text-2xl'>Este contenido no se encuentra disponible por el momento. </p>
            <div className='w-52 mx-auto md:w-60'>
                <SCButton onClick={()=>{navigate("/")}}>Volver</SCButton>
            </div>
        </ContentPageLayout>
    )
}

export default index
